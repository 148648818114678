<template>
  <div class="hello">
    <Header :active="active" />
    <!-- banner -->
    <div class="banner_box">
      <el-carousel :interval="interval" height="49.15rem" trigger="click">
        <el-carousel-item>
          <img src="../assets/images/banner.png" alt="" />
          <div class="button">
            <a href="http://www.csreviews.cn/" target="_blank">查看详情</a>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/images/banner01.jpg" alt="" />
          <div class="button" style="bottom: 200px">
            <a href="javascript:;">查看详情</a>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/images/banner02.png" alt="" />
          <!-- <div class="button">
            <button>查看详情</button>
          </div> -->
        </el-carousel-item>
      </el-carousel>
      <div class="more_btn">
        <i class="icon el-icon-arrow-down"></i>
      </div>
    </div>
    <!-- 安全战略咨询 -->
    <div class="wordcloud_box">
      <div class="contenter">
        <div class="title_box">
          <h2 class="title_name">安全战略咨询</h2>
          <span class="line"></span>
          <p class="slog">为客户提供定制化商业建议并协助客户管理增长</p>
        </div>
        <div class="wordcloud">
          <!-- <Wordcloud /> -->
          <img src="../assets/images/wordcloud.png" alt="" />
        </div>
        <div class="more_btn">
          <i class="icon el-icon-arrow-down"></i>
        </div>
      </div>
    </div>
    <!-- 网络安全全生命周期咨询 -->
    <div class="net_security_box">
      <div class="contenter">
        <div class="title_box">
          <h2 class="title_name">网络安全全生命周期咨询</h2>
          <span class="line"></span>
          <p class="slog">
            专⻔为网络安全行业量身定制的创新咨询模式，在企业生命周期中为其提供卓越运营、企业融资、战略规划、退出规划和并购等服务
          </p>
        </div>
        <div class="net_question_box">
          <div class="left">
            <h4 class="question">什么是全生命周期咨询？</h4>
            <ul class="answer_list">
              <li class="answer_item">
                建议——孵化、运营、合作、融资、管理增长、战略管理、并购重组
              </li>
              <li class="answer_item">
                通路——企业家、企业管理者、董事会成员、投资机构、客户关系、政府关系、公共关系
              </li>
              <li class="answer_item">
                执行——买方并购、卖方并购、资产剥离、企业融资、企业估值、企业发展、风险投资
              </li>
            </ul>
          </div>
          <div class="right">
            <img src="../assets/images/net_zx.png" alt="" />
          </div>
        </div>
        <div class="more_btn">
          <i class="icon el-icon-arrow-down"></i>
        </div>
      </div>
    </div>
    <!-- 为什么选择我们 -->
    <div class="change_result_box">
      <div class="contenter">
        <div class="title_box">
          <h2 class="title_name">为什么选择我们</h2>
          <span class="line"></span>
        </div>
        <div class="result_box">
          <ul class="result_list">
            <li class="result_item">
              <div class="result">
                <p class="iconbox">
                  <img src="../assets/images/experience.png" alt="" />
                </p>
                <h4 class="title">经验</h4>
                <p class="slog">
                  在以网络安全为重点的技术生态系统中拥有无与伦比的丰富经验
                </p>
              </div>
            </li>
            <li class="result_item">
              <div class="result">
                <p class="iconbox">
                  <img src="../assets/images/access.png" alt="" />
                </p>
                <h4 class="title">通路</h4>
                <p class="slog">
                  网络安全领域无与伦比、高度参与的高级网络。我们是充满激情的生
                  态系统建设者。
                </p>
              </div>
            </li>
            <li class="result_item">
              <div class="result">
                <p class="iconbox">
                  <img src="../assets/images/effect.png" alt="" />
                </p>
                <h4 class="title">影响力</h4>
                <p class="slog">
                  随时关注高层。为客户提供完美的执行和24*7的支持
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="more_btn">
        <i class="icon el-icon-arrow-down"></i>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="concat_us_box">
      <div class="contenter">
        <div class="title_box">
          <h2 class="title_name">联系我们</h2>
          <span class="line"></span>
        </div>
        <div class="concat_us">
          <img src="../assets/images/map_info.png" alt="" />
          <div class="info_mag">
            <div>中辰大厦</div>
            <div>北京市朝阳区望京利泽中二路1号</div>
          </div>
        </div>
        <!-- <div class="dress_name">中辰大厦</div> -->
      </div>
    </div>

    <Footer />
    <el-backtop :bottom="100">
      <div class="backtop" style="background-color: #f2f5f6">
        回到<br />顶部
      </div>
    </el-backtop>
    <!-- <div id="app">
    
    </div> -->
  </div>
</template>
<script>
// import Wordcloud from "../components/Wordcloud.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      active: "1",
      interval: 5000,
    };
  },
  methods: {},
  mounted() {},
  updated() {
    window.scroll(0, 0);
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/css/index.less");
</style>